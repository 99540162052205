import { css } from "@emotion/react";
import { CallToAction, Section } from "../components";
import { StyleShape6 } from "../components/Shapes";
import Theme from "../components/Theme";
import { PageLayout } from "../layouts/Page";
import { useSiteData } from "../queries";

const styles = {
  base: css`
    display: flex;
    flex-direction: column;
    position: relative;

    ::before {
      ${StyleShape6};
      right: 0;
      top: -300px;
    }
  `,
  layout: css`
    display: grid;
    grid-gap: 48px;
    align-items: center;
    grid-auto-flow: row;

    ${Theme.mq.sm_} {
      grid-template-columns: minmax(250px, 1fr) minmax(auto, 518px);
    }

    > img {
      object-fit: contain;
      max-width: 100%;
      height: auto;
      justify-self: center;
    }
  `,
};

export default () => {
  const site = useSiteData();
  return (
    <PageLayout title="Not Found" description="OneIQ - Not Found" keywords="404" footerCtaVisible={false}>
      <header css={styles.base}>
        <Section marginTop marginBottom sx={styles.layout}>
          <CallToAction appearance="primary" {...site.notfound} />
          <img src="/assets/404.png" alt="404 - Not Found" width={518} height={466} />
        </Section>
      </header>
    </PageLayout>
  );
};
